exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-business-js": () => import("./../../../src/pages/en/business.js" /* webpackChunkName: "component---src-pages-en-business-js" */),
  "component---src-pages-en-examples-js": () => import("./../../../src/pages/en/examples.js" /* webpackChunkName: "component---src-pages-en-examples-js" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-policy-js": () => import("./../../../src/pages/en/policy.js" /* webpackChunkName: "component---src-pages-en-policy-js" */),
  "component---src-pages-en-recruiting-js": () => import("./../../../src/pages/en/recruiting.js" /* webpackChunkName: "component---src-pages-en-recruiting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-about-js": () => import("./../../../src/pages/ja/about.js" /* webpackChunkName: "component---src-pages-ja-about-js" */),
  "component---src-pages-ja-business-js": () => import("./../../../src/pages/ja/business.js" /* webpackChunkName: "component---src-pages-ja-business-js" */),
  "component---src-pages-ja-examples-js": () => import("./../../../src/pages/ja/examples.js" /* webpackChunkName: "component---src-pages-ja-examples-js" */),
  "component---src-pages-ja-news-js": () => import("./../../../src/pages/ja/news.js" /* webpackChunkName: "component---src-pages-ja-news-js" */),
  "component---src-pages-ja-policy-js": () => import("./../../../src/pages/ja/policy.js" /* webpackChunkName: "component---src-pages-ja-policy-js" */),
  "component---src-pages-ja-recruiting-js": () => import("./../../../src/pages/ja/recruiting.js" /* webpackChunkName: "component---src-pages-ja-recruiting-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

